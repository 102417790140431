section {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

section:nth-child(even) {
	flex-direction: row-reverse;
}

section img,
section div {
	height: auto;
	width: 100%;
}

section img {
	width: 50%;
}

section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
}

@media screen and (max-width: 900px) {
	section,
	section:nth-child(even) {
		flex-direction: column;
	}

	section img {
		height: auto;
		width: auto;
		max-width: 100vw;
	}
}